/*SocGeol stylesheet*/

html {
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: 400 !important;
  color: #333;
}

body {
  background: #35291f !important;
  font-family: "Barlow";
}

h1 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600 !important;
}

h2 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.4em !important;
  font-weight: 600 !important;
}

h3 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.3em !important;
}

h4 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.1em !important;
}

h5 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1em !important;
}

/*colors*/
.bg-darkbrown {
  background-color: #35291f !important;
}

.bg-brown {
  background-color: #482714 !important;
}

.bg-lightbrown {
  background-color: #988f76 !important;
}

.bg-darkorange {
  background-color: #6c3108 !important;
}

.bg-orange {
  background-color: #c57200 !important;
}

.bg-yellow {
  background-color: #eedb74 !important;
}

.bg-darkgreen {
  background-color: #333c1a !important;
}

.bg-green {
  background-color: #66883f !important;
}

.bg-darkgray {
  background-color: #45515d !important;
}

.bg-gray {
  background-color: #7a8491 !important;
}

.bg-lightgray {
  background-color: #adb6bb !important;
}

.bg-lightest {
  background-color: #dbdfe1 !important;
}

.txt-darkbrown {
  color: #35291f !important;
}

.txt-brown {
  color: #482714 !important;
}

.txt-lightbrown {
  color: #988f76 !important;
}

.txt-darkorange {
  color: #6c3108 !important;
}

.txt-orange {
  color: #c57200 !important;
}

.txt-yellow {
  color: #eedb74 !important;
}

.txt-darkgreen {
  color: #333c1a !important;
}

.txt-green {
  color: #66883f !important;
}

.txt-darkgray {
  color: #45525d !important;
}

.txt-gray {
  color: #7a8491 !important;
}

.txt-lightgray {
  color: #adb6bb !important;
}

.btn-fixed-width {
  width: 170px;
}
.btn-primary {
  color: #fff !important;
  background-color: #c57200 !important;
  border-color: #6c3108 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #e28506 !important;
  border-color: #6c3108 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c4b1a !important;
  border-color: #482714 !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #6e5735 !important;
  border-color: #482714 !important;
}

.btn-success {
  color: #fff !important;
  background-color: #66883f !important;
  border-color: #333c1a !important;
}

.btn-success:hover {
  color: #fff !important;
  background-color: #80a851 !important;
  border-color: #333c1a !important;
}

.text-shadow {
  text-shadow: 3px 3px 3px #000000;
}

.display {
  display: block;
}

.bold {
  font-weight: 600;
}

a {
  color: #c57200 !important;
  text-decoration: none !important;
  font-weight: 600;
}

a:hover {
  color: #e28506 !important;
  text-decoration: none !important;
}

/*///// CUSTOMIZATION //////*/

.card-title a {
  font-family: "Barlow";
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: large;
}

.card-title a:hover {
  color: #ffffff !important;
  font-family: "Barlow";
  text-decoration: none !important;
  text-decoration: underline !important;
}

/*Overrides gerais*/

/*Navbar*/

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  font-family: "Barlow", Arial, sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em !important;
  margin-left: 5%;
}

.nav-link {
  color: #ffffff !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin-right: 20px;
}

.nav-link:hover {
  color: #c57200 !important;
}

.dropdown-menu {
  background-color: #000 !important;
  color: white !important;
}

.dropdown-item {
  color: #ffffff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #c57200 !important;
  color: #000000 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #c57200 !important;
  color: #000000 !important;
}

/*Carousel*/
.carousel-caption {
  bottom: 5rem !important;
}

/*Main*/
main {
  padding-top: 70px !important;
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 575.98px) {
  .navbar-brand {
    max-height: 50px !important;
    margin-right: 1px !important;
    margin-bottom: 20px !important;
  }

  h1 {
    font-size: 0.8em !important;
  }

  .carousel-caption {
    display: none !important;
  }
}

/*Small devices - landscape phones, less than 768px*/
@media only screen and (max-width: 767.98px) {
  .carousel-caption {
    display: none !important;
  }
}

/*Medium devices - tablets, less than 992px*/
@media only screen and (min-width: 991.98px) {
  h1 {
    font-size: 1em !important;
  }
}

/*Large devices - desktops, less than 1200px*/
@media only screen and (min-width: 1199.98px) {
  .display {
    display: none !important;
  }

  .navbar-nav {
    font-size: 0.9em !important;
  }
}

/*Bigger devices*/
@media only screen and (min-width: 1600px) {
  .display {
    display: block !important;
  }

  .navbar-nav {
    font-size: 1em !important;
  }

  h1 {
    font-size: 1.4em !important;
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 1px;
  margin: 10px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #eedb74;
  box-shadow: 0 -1px 0 rgb(0 0 0 / 1%);
}

.accordion:hover {
  font-weight: 800;
}

.slide-custom {
  width: 100%;
  object-position: 50% 50%;
  object-fit: cover;
  min-height: 366px;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #000000;
  outline: 0;
  font-weight: 800;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 1%);
}

/* .card-img-sameh {
  width: 100%;
  height: 23vh;
  object-fit: cover;
} */

.card-img-sameh {
  width: 100%;
  min-height: 175px;
  height: 25vh;
  object-fit: cover;
}

.carousel-control-next,
.carousel-control-prev

/*, .carousel-indicators */ {
  height: 520px;
  width: 40px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

img.slide {
  width: 100%;
  height: 32vh;
  object-position: 10% 10%;
  object-fit: cover;
  max-height: 120vh;
}

.nav-link-tab {
  color: #000000 !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin: 2px;
  border: 1px solid #e1e3e7 !important;
  font-size: small;
}

#user-data-tab-profile,
#user-data-tab-quotes {
  color: #000000 !important;
}

.nv {
  color: #000000 !important;
}

.nv:active {
  color: #000000 !important;
}

.nv:hover {
  color: #c57200;
}

.link,
.link:hover {
  color: #c57200 !important;
  text-decoration: none !important;
  font-weight: 600;
  background-color: #f8f9fa;
}

.link:hover {
  background-color: #f8f9fa !important;
}

.link:active {
  color: #c57200 !important;
  background-color: #f8f9fa !important;
}

.link:visited {
  color: #c57200 !important;
  background-color: #f8f9fa !important;
}

/*Labs stylesheet*/

a {
  color: #eedb74 !important;
  text-decoration: none !important;
  font-weight: 600;
}

a:hover {
  color: #c3b56b !important;
  text-decoration: none !important;
}

body {
  background: #adb6bb !important;
}

/*///// CUSTOMIZATION //////*/

/*Overrides gerais*/

/*Navbar*/

.nav-link {
  color: #fff !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin-right: 20px;
}

.nav-link:hover {
  color: #eedb74 !important;
}

.dropdown-menu {
  background-color: #45525d !important;
  color: #fff !important;
}

.dropdown-item {
  color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #eedb74 !important;
  color: #333 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #eedb74 !important;
  color: #333 !important;
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 575.98px) {
  .btn {
    font-size: 12px !important;
  }

  .btn-small {
    font-size: 8px !important;
  }
}

/*Small devices - landscape phones, less than 768px*/
@media only screen (max-width: 767.98px) {
}

/*Medium devices - tablets, less than 992px*/
@media only screen and (min-width: 991.98px) {
}

/*Large devices - desktops, less than 1200px*/
@media only screen and (min-width: 1199.98px) {
}

/*Bigger devices*/
@media only screen and (min-width: 1600px) {
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

/* medium and up screens */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.embed-youtube iframe {
  width: 100%;
}
.navbar-nav,
.navbar {
  padding-top: 0px !important;
}
